import {Title} from "../enums/title.enum";
import {UserRole} from "../enums/user-role.enum";

export class Person {
  id: number;
  firstName: string;
  lastName: string;
  title: Title;
  jobTitle: string;
  email: string;
  role: UserRole;
  initials: string;
  mailbox?: string | null;
  isCrmUser?: boolean;
  userSub?: string | null;
  fullName?: string;
  assignee?: Person | null;
  officePhoneNumber?: string | null;
  mobilePhoneNumber?: string | null;
  constructor(id: number, firstName: string, lastName: string, title: Title, jobTitle: string, email: string,
              role: UserRole, initials: string, mailbox?: string, isCrmUser?: boolean, userSub?: string | null, fullName?: string,
              assignee?: Person, officePhoneNumber?: string, mobilePhoneNumber?: string) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.title = title;
    this.jobTitle = jobTitle;
    this.email = email;
    this.role = role;
    this.initials = initials;
    this.mailbox = mailbox;
    this.isCrmUser = isCrmUser;
    this.userSub = userSub;
    this.fullName = fullName;
    this.isCrmUser= isCrmUser;
    this.assignee = assignee || null;
    this.officePhoneNumber = officePhoneNumber;
    this.mobilePhoneNumber = mobilePhoneNumber;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

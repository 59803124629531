<div class="relative" [pTooltip]="fullName" tooltipPosition="bottom" [tooltipDisabled]="!showNameTooltip"
     [positionTop]="5" (click)="onAvatarClick(selectOverlay, $event)">
  @if (check) {
    <div class="avatar-check">
      <img ngSrc="assets/icons/check-mark.svg" alt="Check Mark Icon" height="8" width="8">
    </div>
  }
  @if (selectedUser) {
    <div [class]="avatarColorScheme" [ngClass]="static ? 'avatar-static' : 'avatar'">
      <p>
        {{ selectedUser.person.initials }}
      </p>
    </div>
  } @else {
    <div [ngClass]="!selectedUser && static ? 'avatar-static text-neutral-800 bg-neutral-200'
      : 'avatar text-neutral-800 bg-neutral-200'">
      <p>
        {{ !selectedUser && static ? '-' : '+' }}
      </p>
    </div>
  }
</div>
<p-overlayPanel #selectOverlay [style]="{ width: '240px', cursor: 'default' }" (onHide)="searchFormControl.reset()">
  <ng-template pTemplate="content">
    <div class="flex flex-col gap-[5px] pr-0.5">
      <div class="flex flex-col gap-[5px] border-b-[1px] border-b-neutral-200 p-[8px] prevent-close">
        <p class="text-m text-neutral-900 font-medium">Add people <span
          class="text-neutral-700">{{ selectedUser ? '1' : '0' }}/1</span></p>
        <div class="crm-input">
          <input type="text" placeholder="Enter name" class="!px-3 !py-2.5" [formControl]="searchFormControl">
        </div>
      </div>
      <div class="flex flex-col gap-[5px] h-[210px] overflow-y-scroll">
        @for (user of usersList; track user.person.id) {
          @if (user.person.id !== selectedUser?.person.id) {
            <div class="flex items-center justify-between p-[6px] pr-0 pl-0 ml-1.5 rounded-[6px] cursor-pointer
             hover:bg-neutral-100 transition-colors"
              (click)="toggleUser(user.person.id, selectOverlay)">
              <div class="flex items-center gap-[8px]">
                <div [class]="'avatar-static ' + getColorScheme(user.person.getFullName())">
                  {{ user.person.initials }}
                </div>
                <div>
                  <p class="text-base text-neutral-900 font-medium">
                    {{ user.person.getFullName() }}
                  </p>
                  <p class="text-s text-neutral-700">{{ UserRole[user.role] | titlecase }}</p>
                </div>
              </div>
              @if (user.person.id != selectedUser?.person.id) {
                <span [inlineSVG]="'assets/icons/replace.svg'" class="cursor-pointer mr-0.5"></span>
              }
            </div>
          }
        } @empty {
          <div class="w-full h-full flex justify-center items-center mb-[12px]">
            <p class="text-neutral-900">No person to choose from!</p>
          </div>
        }
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

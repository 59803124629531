export enum SectorIndustryEnum {
  ASSOCIATION= "ASSOCIATION",
  ATOZ= "ATOZ",
  AVIATION = "AVIATION",
  BANKING_CAPITAL_MARKETS = "BANKING_CAPITAL_MARKETS",
  BIG_4 = "BIG_4",
  BUSINESS_PROFESSIONAL_SERVICES = "BUSINESS_PROFESSIONAL_SERVICES",
  COMPETITORS = "COMPETITORS",
  CONSTRUCTION = "CONSTRUCTION",
  CONSUMER_GOODS_SERVICES = "CONSUMER_GOODS_SERVICES",
  INFRASTRUCTURE = "INFRASTRUCTURE",
  ENERGY_OIL_GAS = "ENERGY_OIL_GAS",
  EDUCATION = "EDUCATION",
  FAMILY_OFFICES_WEALTH_MANAGEMENT = "FAMILY_OFFICES_WEALTH_MANAGEMENT",
  FINANCIAL_SERVICES = "FINANCIAL_SERVICES",
  GOVERNMENT_PUBLIC_SECTOR = "GOVERNMENT_PUBLIC_SECTOR",
  HEALTHCARE_LIFE_SCIENCES = "HEALTHCARE_LIFE_SCIENCES",
  HNWI = "HNWI",
  INSURANCE="INSURANCE",
  INVESTMENT_MANAGEMENT="INVESTMENT_MANAGEMENT",
  LAW_FIRMS_COMPETITORS="LAW_FIRMS_COMPETITORS",
  LAW_FIRMS_LEGAL_SERVICES="LAW_FIRMS_LEGAL_SERVICES",
  MANUFACTURING_ENGINEERING_CHEMICALS="MANUFACTURING_ENGINEERING_CHEMICALS",
  MARKETING_ADVERTISING_SALES="MARKETING_ADVERTISING_SALES",
  OTHER_SERVICES="OTHER_SERVICES",
  PRIVATE_EQUITY="PRIVATE_EQUITY",
  REAL_ESTATE="REAL_ESTATE",
  SERVICE_PROVIDERS="SERVICE_PROVIDERS",
  SOVEREIGN_WEALTH="SOVEREIGN_WEALTH",
  SPORTS_ENTERTAINMENT="SPORTS_ENTERTAINMENT",
  TAXAND="TAXAND",
  TECHNOLOGY_MEDIA_TELECOMMUNICATIONS="TECHNOLOGY_MEDIA_TELECOMMUNICATIONS",
  TRANSPORT_LOGISTICS="TRANSPORT_LOGISTICS",
  TRUST_COMPANY="TRUST_COMPANY",
  VENTURE_CAPITAL="VENTURE_CAPITAL",
  WHT="WHT"
}

import {Pipe, PipeTransform} from '@angular/core';
import {Title} from "../enums/title.enum";

@Pipe({
  name: 'honorificTitle',
  standalone: true
})
export class HonorificTitlePipe implements PipeTransform {

  transform(value: string) {
    switch (value) {
      case Title.MR:
        return "Mr";
      case Title.MRS:
        return "Mrs";
      default:
        return "";
    }
  }

}

export enum CompanyLegalFormEnum {
  ADMINISTRATION_GOVERNMENT = "ADMINISTRATION_GOVERNMENT",
  AG_AND_CO_KGAA = "AG_AND_CO_KGAA",
  AG = "AG",
  ASBL = "ASBL",
  BVBA = "BVBA",
  CORP = "CORP",
  GIE = "GIE",
  GMBH_AND_CO_KG = "GMBH_AND_CO_KG",
  GMBH = "GMBH",
  HNWI = "HNWI",
  INC = "INC",
  KG = "KG",
  LLC = "LLC",
  LLP = "LLP",
  LP = "LP",
  LTD = "LTD",
  NV = "NV",
  PLC = "PLC",
  PLLC = "PLLC",
}

import {Injectable} from "@angular/core";
import {ModalType} from "../../enums/global/modal-type.enum";
import {IconName} from "../../enums/global/icon-name.enum";
import {InfoCardConfigModel, InfoCardTextConfig} from "../../models/global/info-card-config.model";
import {Person} from "../../models/person.model";
import {AvatarUser} from "../../components/avatar-component/models/avatar-user.model";
import {FormGroup} from "@angular/forms";
import moment from "moment";
import CryptoJS from "crypto-js";
import {Router} from "@angular/router";
import {GdprRequestDetailsModel} from "../../models/gdpr/gdpr-request-details.model";
import {ContactState} from "../../enums/contact-state.enum";

@Injectable({
  providedIn: 'root'
})
/**
 * The UtilsService provides utility methods that are used globally throughout the application.
 */
export class UtilsService {

  constructor(private readonly router: Router) {
  }

  /**
   * Returns the CSS class for the modal icon based on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  cssClass(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return 'featured-icon-primary-xl';
      case ModalType.ERROR:
        return 'featured-icon-error-xl';
      case ModalType.WARNING:
        return 'featured-icon-warning-xl';
      case ModalType.SECONDARY:
        return 'featured-icon-gray-xl';
      default:
        return 'featured-icon-primary-xl';
    }
  }

  /**
   * Returns the CSS class for the confirmation button based on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  confirmButtonClass(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return 'btn-primary';
      case ModalType.ERROR:
        return 'btn-danger';
      case ModalType.WARNING:
        return 'btn-danger';
      default:
        return 'btn-primary';
    }
  }

  /**
   * Checks if the specified GDPR request is cancelled or expired.
   * To be used to customize the GDPR accordion style and to know if we could send new GDPR requests or not
   * @param gdprRequest GDPR request {@link GdprRequestDetailsModel}
   */
  isGdprCanceledOrExpired(gdprRequest: GdprRequestDetailsModel):boolean {
    return !!gdprRequest.cancellationDate ||  moment() >= gdprRequest.expirationDate;
  }

  /**
   * Returns icon name to use in the modal on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  modalIconName(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return IconName.CHECKED_CIRCLE;
      case ModalType.ERROR:
        return IconName.ALERT_CIRCLE;
      case ModalType.WARNING:
        return IconName.ALERT_TRIANGLE;
      default:
        return IconName.CHECKED_CIRCLE;
    }
  }

  /**
   * Builds and returns the config of awaiting GDPR preferences card info.
   * Displayed when a contact has an ongoing GDPR request or no requests at all.
   */
  getWaitingGDPRRequestCardConfig() {
    return new InfoCardConfigModel(
      ModalType.SECONDARY,
      new InfoCardTextConfig(
        'Awaiting GDPR Preferences',
        '20px'
      ),
      new InfoCardTextConfig(
        `We're waiting to receive this contact's GDPR preferences.
       <br>Once the contact submits their preferences, the information will be updated here.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }
  /**
   * Builds and returns the config of canceled GDPR preferences card info.
   */
  getGDPRRequestCanceledCardConfig() {
    return new InfoCardConfigModel(
      ModalType.ERROR,
      new InfoCardTextConfig(
        'This request has been canceled.',
        '20px'
      ),
      new InfoCardTextConfig(
        `The request you initiated has been canceled.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }

  /**
   * Builds and returns the config of an expired GDPR request card info.
   */
  getGDPRRequestExpiredCardConfig() {
    return new InfoCardConfigModel(
      ModalType.ERROR,
      new InfoCardTextConfig(
        'This request has expired.',
        '20px'
      ),
      new InfoCardTextConfig(
        `The request you initiated has expired, and no action was taken.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }

  /**
   * Builds and returns the config of No GDPR Preferences card info.
   * Displayed when a contact has an ongoing GDPR request or no requests at all.
   */
  getGDPRRequestCardConfig() {
    return new InfoCardConfigModel(
      ModalType.SECONDARY,
      new InfoCardTextConfig(
        'No GDPR Preferences',
        '20px'
      ),
      new InfoCardTextConfig(
        `No GDPR consent request has been sent yet.
       <br>Once a request is made and preferences are submitted by the contact, the information will be updated here.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }

  /**
   * Builds and returns the config of Disabled GDPR communication card info.
   * Displayed when a contact's GDPR communication are disabled.
   */
  getDisabledGDPRCommunicationCardConfig() {
    return new InfoCardConfigModel(
      ModalType.SECONDARY,
      new InfoCardTextConfig(
        'GDPR Communication Disabled',
        '20px'
      ),
      new InfoCardTextConfig(
        `The owner of this contact has disabled GDPR-related communication.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }

  createAvatarUsers(persons: Person[], check: boolean = false): AvatarUser[] {
    return persons.map((person: Person) => new AvatarUser(person, person.role, check));
  }

  /**
   * This method checks if a form control identified by the specified name
   * within the specified form group has errors of the specified validation type.
   * @param formGroup       form group {@link FormGroup}
   * @param controlName     form control name (ex: firstName,...)
   * @param validationType  validation applied to form control (ex: required,...)
   */
  isControlHasError(formGroup: FormGroup, controlName: string, validationType: string): boolean {
    let control = formGroup.get(controlName);
    if (!control) {
      return false;
    }
    return control.hasError(validationType) && (control.dirty || control.touched);
  }


  /**
   * Adapts the given date to UTC timezone.
   *
   * @param date - The date to be adapted. It should be a moment.js Moment object.
   *
   * @returns The adapted date in UTC timezone. If the input date is null or undefined,
   *          the function will return null.
   */
  adaptUtcTimeZone(date: moment.Moment) {
    if (date) {
      // Convert the date to UTC without modifying the original date object
      return date.utc(true);
    } else {
      // Return null if the provided date is invalid
      return null;
    }
  }

  /**
   * Determines if a tooltip should be shown for a given text based on its length
   * and the available container width.
   *
   * @param text - The text to be evaluated for overflow.
   * @param containerWidth - The container width.
   * @returns `true` if the text exceeds the container width and requires a tooltip, otherwise `false`.
   *
   * */
  showTooltip(text: string, containerWidth: string | number) {
    // If text is null or undefined, no tooltip is required
    if (!text) return false;
    const width = typeof containerWidth === 'string' ? parseFloat(containerWidth) : containerWidth;
    return this.doesTextOverflow(width, text.length);
  }

  /**
   * Determines if the given text will overflow a container with a specified width in rem.
   *
   * @param remWidth - The width of the container in rem.
   * @param charCount - The number of characters in the text.
   * @returns `true` if the text overflows the container, otherwise `false`.
   */
  doesTextOverflow(remWidth: number, charCount: number): boolean {
    // Convert the container width from rem to pixels, assuming the root font size is 12px.
    const divWidth = remWidth * 12;

    // Estimate the average width of a character in pixels.
    // Assuming a character width ratio of 0.50 for the given font size.
    const charWidth = 12 * 0.52;

    // Calculate the total width of the text in pixels.
    const totalTextWidth = charWidth * charCount;

    // Compare the total text width with the container width.
    // Return true if the text width exceeds the container width (overflow).
    return totalTextWidth > divWidth;
  }

  /**
   * Prepares a URL link for a specific entity by encoding its ID and
   * generating a URL with said ID as a query parameter.
   *
   * This generated URL can be used to navigate to the specified target route,
   * with the side panel or other contextual information preloaded for that entity.
   *
   * @param entityId The ID of the entity to be encoded and used in the URL.
   * @param targetRoute The target route where the URL should navigate to (ex: 'contacts/all').
   * @returns A string representing the full URL, including the base origin and query parameters.
   */
  prepareEntityLink(entityId: number, targetRoute: string) {
    // Step 1: Encode the entity ID using Base64url encoding.
    const encodedEntityId = CryptoJS.enc.Base64url.stringify(
      CryptoJS.enc.Utf8.parse(entityId.toString())
    );

    // Step 2: Create a URL tree for the target route with the encoded entity ID as a query parameter.
    const urlTree = this.router.createUrlTree([targetRoute], {
      queryParams: { key: encodedEntityId },
    });

    // Step 3: Serialize the URL tree into a string, forming the full URL with base origin and query parameter.
    return window.location.origin + this.router.serializeUrl(urlTree);
  }

  prepareEntityLinkDashboard(entityId: number, targetRoute: string, status?: ContactState): string {
    const encodedEntityId = CryptoJS.enc.Base64url.stringify(
      CryptoJS.enc.Utf8.parse(entityId.toString())
    );

    const queryParams: any = { key: encodedEntityId };
    if (status) {
      queryParams.status = status;
    }

    const urlTree = this.router.createUrlTree([targetRoute], {
      queryParams
    });

    return window.location.origin + this.router.serializeUrl(urlTree);
  }

  /**
   * Recursively sorts the keys of an object and the values of arrays to ensure a consistent structure.
   * This is useful for generating stable hashes or performing deep comparisons.
   *
   * @param obj - The input object or array to be sorted.
   * @returns A new object or array with sorted keys and values.
   */
  sortObjectKeysAndValues(obj: any): any {
    // If the input is an array, sort its elements
    if (Array.isArray(obj)) {
      return obj
        .map(item =>
          // If an item is an object, sort its keys as well
          (typeof item === 'object' ? this.sortObjectKeysAndValues(item) : item)
        )
        // Sort the array values (if they are strings)
        .sort((a, b) => a?.localeCompare?.(b) ?? 0);
    }
    // If the input is an object, sort its keys alphabetically
    else if (obj && typeof obj === 'object') {
      return Object.keys(obj)
        .sort() // Sort the keys alphabetically
        .reduce((acc, key) => {
          // Recursively sort values associated with each key
          acc[key] = this.sortObjectKeysAndValues(obj[key]);
          return acc;
        }, {} as Record<string, any>); // Initialize an empty object
    }

    // Return the value as is if it's neither an object nor an array
    return obj;
  };
}

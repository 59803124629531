export enum GDPRConsentEnum {
  DOT_DIGITAL = 'DOT_DIGITAL',
  ATOZ_NEWS = 'ATOZ_NEWS',
  ATOZ_EVENTS = 'ATOZ_EVENTS',
  DEVECO_NEWS = 'DEVECO_NEWS',
  DEVECO_EVENTS = 'DEVECO_EVENTS',
  DECLINE_UNSUBSCRIBE = 'DECLINE_UNSUBSCRIBE'
}

export enum GDPRConsentLabel {
  DOT_DIGITAL = 'Dotdigital consent',
  ATOZ_NEWS = 'News from ATOZ',
  DEVECO_NEWS = 'News from Deveco',
  ATOZ_EVENTS = 'Event invitations from ATOZ',
  DEVECO_EVENTS = 'Event invitations from Deveco',
  DECLINE_UNSUBSCRIBE = 'Decline / Unsubscribe'
}

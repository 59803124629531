import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {User} from "../shared/models/user.model";
import {environment} from "../../environments/environment";
import {Person} from "../shared/models/person.model";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly ME_URL = 'users/me'
  public currentUser = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient) {
  }

  getAuthenticatedUser(): Observable<User> {
    return this.http.get<User>(environment.apiUrl + this.ME_URL)
      .pipe(
        tap((user) => this.currentUser.next(
            new User(
                user.id,
              user.sub,
              new Person(
                user.person.id,
                user.person.firstName,
                user.person.lastName,
                user.person.title,
                user.person.jobTitle,
                user.person.email,
                user.person.role,
                user.person.initials,
                user.person.mailbox,
                user.person.isCrmUser,
                user.person.userSub,
                user.person.fullName,
                user.person.assignee,
                user.person.officePhoneNumber,
                user.person.mobilePhoneNumber,
              )
            )
          )
        ));
  }

}

import {EnumLabelValueInterface} from "../interfaces/enum-label-value.interface";

/**
 * Transforms and sorts an enum object into an array of EnumLabelValueInterface objects.
 *
 * @template T - The type of enum values.
 * @param enumObject - The enum object to transform and sort.
 * @param pipe - An optional pipe to transform the enum keys. If provided, it should have a `transform` method.
 * @returns An array of EnumLabelValueInterface objects, sorted alphabetically by label.
 *          If 'Others' is present, it is placed at the end of the list.
 *          If 'Not Applicable' is present, it is placed at the end.
 */
export default function transformAndSortEnum<T>(enumObject: object, pipe?: any): EnumLabelValueInterface<T>[] {
  return Object.entries(enumObject)
    .map(([key, val]) => {
      let label: string;

      if (pipe) {
        // If a pipe is provided, use it to transform the enum key
        const pipeInstance = new pipe();
        label = pipeInstance.transform(key);
      } else {
        // If no pipe is provided, apply default transformation
        label = key
          .toLowerCase()
          .replace(/_/g, ' ') // Replace underscores with spaces
          .replace(/^\w/, c => c.toUpperCase()); // Capitalize the first letter
      }

      return {
        label,
        value: val as T
      };
    })
    .sort((a, b) => {
      // Prioritize "Not Applicable" at the end of the list
      if (a.label === 'Not applicable') return 1;
      if (b.label === 'Not applicable') return -1;

      // If 'a' or 'b' has the label "Others", place it at the end of the list
      if (a.label === 'Others') return 1;
      if (b.label === 'Others') return -1;

      // Otherwise, sort alphabetically by label
      return a.label.localeCompare(b.label);
    });
}

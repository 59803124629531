import {GDPRStatusEnum} from "../../enums/gdpr/gdpr-state.enum";

export class GdprConsentModel {
  status: GDPRStatusEnum
  atozNewsConsent: boolean | null;
  atozEventsConsent: boolean | null;
  devecoNewsConsent: boolean | null;
  dotDigitalConsent: boolean | null;
  devecoEventsConsent: boolean | null;
  declineUnsubscribe: boolean | null;
  createdAt: Date;
  modifiedAt: Date;


  constructor(
    status: GDPRStatusEnum,
    atozNewsConsent: boolean | null,
    atozEventsConsent: boolean | null,
    dotDigitalConsent: boolean | null,
    devecoNewsConsent: boolean | null,
    devecoEventsConsent: boolean | null,
    declineUnsubscribe: boolean | null,
    createdAt: Date,  // added createdAt property to track when consent was given
    modifiedAt: Date
  ) {
    this.status = status;
    this.atozNewsConsent = atozNewsConsent;
    this.atozEventsConsent = atozEventsConsent;
    this.dotDigitalConsent = dotDigitalConsent;
    this.devecoNewsConsent = devecoNewsConsent;
    this.devecoEventsConsent = devecoEventsConsent;
    this.declineUnsubscribe = declineUnsubscribe;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
  }
}

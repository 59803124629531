import {ContactTypeClientInfoEnum} from "./contact-type-client-info.enum";
import {ContactTypeNonClientInfoEnum} from "./contact-type-non-client-info.enum";

export enum ContactTypeInfoEnum {
  ACTIVE_CLIENT = ContactTypeClientInfoEnum.ACTIVE_CLIENT,
  DORMANT_CLIENT = ContactTypeClientInfoEnum.DORMANT_CLIENT,
  ALUMNI = ContactTypeNonClientInfoEnum.ALUMNI,
  ATOZ = ContactTypeNonClientInfoEnum.ATOZ,
  CIVIL_ASSOCIATION = ContactTypeNonClientInfoEnum.CIVIL_ASSOCIATION,
  COMPETITORS_TO_ATA = ContactTypeNonClientInfoEnum.COMPETITORS_TO_ATA,
  FRIENDS_OF_ATOZ = ContactTypeNonClientInfoEnum.FRIENDS_OF_ATOZ,
  INDEPENDENT_FREELANCE = ContactTypeNonClientInfoEnum.INDEPENDENT_FREELANCE,
  LAW_FIRM = ContactTypeNonClientInfoEnum.LAW_FIRM,
  LOCAL_INFLUENCER = ContactTypeNonClientInfoEnum.LOCAL_INFLUENCER,
  MARKETING_CONTACT = ContactTypeNonClientInfoEnum.MARKETING_CONTACT,
  OFFICIALS = ContactTypeNonClientInfoEnum.OFFICIALS,
  POTENTIAL_CLIENT = ContactTypeNonClientInfoEnum.POTENTIAL_CLIENT,
  PROFESSIONAL_ASSOCIATION = ContactTypeNonClientInfoEnum.PROFESSIONAL_ASSOCIATION,
  SERVICE_PROVIDERS = ContactTypeNonClientInfoEnum.SERVICE_PROVIDERS,
  TAXAND = ContactTypeNonClientInfoEnum.TAXAND,
}

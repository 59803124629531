export enum ContactIndustry {
  PRIVATE_EQUITY = "PRIVATE_EQUITY",
  REAL_ESTATE = "REAL_ESTATE",
  VENTURE_CAPITAL = "VENTURE_CAPITAL",
  DEBT = "DEBT",
  INFRASTRUCTURE = "INFRASTRUCTURE",
  MULTINATIONALS = "MULTINATIONALS",
  FAMILY_OFFICE_HNWI = "FAMILY_OFFICE_HNWI",
  BANKS_FINANCIAL_INSTITUTIONS = "BANKS_FINANCIAL_INSTITUTIONS",
  WHT_PROJECT = "WHT_PROJECT",
  AVIATION_FINANCE = "AVIATION_FINANCE",
  LAW_FIRM = "LAW_FIRM",
  OTHER_SERVICE_PROVIDER = "OTHER_SERVICE_PROVIDER",
  OTHERS = "OTHERS"
}
